@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/SpoqaHanSansNeo-Regular.otf");
  src: local("Spoqa Han Sans Neo"), local("SpoqaHanSansNeo-Regular.otf"),
    url("../assets/font/SpoqaHanSansNeo-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/font/SpoqaHanSansNeo-Medium.otf");
  src: local("Spoqa Han Sans Neo"), local("SpoqaHanSansNeo-Medium.otf"),
    url("../assets/font/SpoqaHanSansNeo-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/font/SpoqaHanSansNeo-Bold.otf");
  src: local("Spoqa Han Sans Neo"), local("SpoqaHanSansNeo-Bold.otf"),
    url("../assets/font/SpoqaHanSansNeo-Bold.otf") format("opentype");
}
